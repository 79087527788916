.container1 {
    width: 900px;
    position: absolute;
    top: 34%;
    left: 19%;
    text-align: center;
}

.containerOverViewData {
  padding:40px;
  position: fixed;
  float:right;
  color:white;
  margin-right:20px;
  left:24rem;
  top: 1.2rem;
  display: flex;
  justify-content: space-evenly;
  /* display: grid;
  grid-template-columns: 8.5rem 8.5rem 8.5rem 8.5rem 8.5rem 8.5rem 8.5rem 8.5rem 8.5rem;
  text-align: center;
  justify-content: space-evenly;
  grid-gap: 0rem;
  position: fixed;
  top: 3rem;
  left: 25rem;
  color:#fffffe; */
}

.containerOverViewData h4 {
  font-family: Impact;
  margin: 10px;
}

.containerOverViewData h5 {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  margin: 10px;
  padding-top: 10px;
}

.containerOverViewData .row {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.containerCoporateProfileSetup {
    width: 100vw;
    height: 64vh;
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction: column;
    left: 0%;
    justify-content: center;
    align-items: center;
}

.containercoursemanagersetup {
  width: 900px;
  position: absolute;
  top: 21%;
  left: 27%;
}

.container2 {
  width: 1200px;
  height: 600px;
  position: absolute;
  text-align: center;
  top: 15%;
  left: 18%;
}

.containerTraineeUserAccount {
  width: 1200px;
  height: 600px;
  position: absolute;
  text-align: center;
  top: 9%;
  left: 15%;
}

.container3 {
  width: 900px;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container3 h3 {
  color: #FFD60F;
}

.container4 {
  width: 900px;
  height: 600px;
  position: absolute;
  text-align: center;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
}

.container5 {
  width: 1200px;
  height: 600px;
  position: absolute;
  text-align: center;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
}

.container6 {
  width: 1200px;
  height: 600px;
  position: absolute;
  text-align: center;
  top: 49%;
  left: 54%;
  transform: translate(-50%, -50%);
}

.containerUserAccountSetup {
  width: 82vw;
  height: 100%;
  position: fixed;
  text-align: center;
  top: 19vh;
  left: 19vh;
  border-radius: 12px;
}

.button-container {
  width: 80rem;
  cursor: pointer;
  padding-top: 2rem;
}

.button-container1 {
  width: 75rem;
  position: fixed;
  text-align: center;
  cursor: pointer;
  padding-top: 2rem;
  left: 24rem;
}

.button-container3 {
  width: 80rem;
  position: fixed;
  /* top: 58rem;
  left: 3rem; */
  cursor: pointer;
  padding-top: 2rem;
  left: 15%;
}

/* Authoring Page */

.container-authoring-platform {
  width: 1000px;
  height: 600px;
  position: absolute;
  text-align: center;
  top: 11vh;
  left: 51vh;
}

.name-info {
  display: flex;
  text-align: center;
  justify-content: space-evenly;
  position: fixed;
  float: left;
  left: 3rem;
  top: 1.8rem;
}
.name-info h2 {
  color: #FCFDFA;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 1.3rem;
}

.name-info img {
  width: 8rem;
  height: 2rem;
  margin-top: 1.8rem;
  margin-right: 3rem;
}

.toggle-button {
  display: flex;
  justify-content: space-between;
  position: absolute;
  text-align: center;
  top: 75%;
  left: 49%;
}

.fas {
  padding: 7px;
  color: white;
}


.get-back-btn {
  position: fixed;
  text-align: center;
  /* top: 65rem; */
  bottom: 1rem;
  /* left: 105rem; */
  right: 2rem;
  cursor: pointer;
}

.course-manager-back-btn {
  position: absolute;
  text-align: center;
  bottom: 1rem;
  right: 2rem;
  cursor: pointer;
}

.upload {
  cursor: pointer;
  width: 100%;
}

.table{
  color: #FFD60F;
}

.infochart {
  background-color: grey;
  opacity: 0.5;
}

/* General Button Style */
/* atempted to create button on 29 Apr but still the photo version looks better */
/* button test */
.button{
  position: relative;
	display:block;
	background: transparent;
	width: 250px;
	height:60px;
	line-height:60px;
	text-align:center;
	font-size:15px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
	text-decoration:none;
	text-transform:uppercase;
	margin:40px auto;
  box-sizing: unset;
  left: 5.5rem;

}
.button:before, .button:after {
	width:25rem;
  left: 0px;
	height:27px;
  z-index: -1;
  background-color: #cdbb1d;
}
.white{
  color:#fffffe;
}

/* Button Border Style */
.white:before,.white:after {
	border: 3px solid white;
}

.button:before{
  position: absolute;
  content: '';
  border-bottom: none;
  -webkit-transform: perspective(15px) rotateX(5deg);
  -moz-transform: perspective(15px) rotateX(5deg);
  transform: perspective(15px) rotateX(5deg);
}
.button:after{
  position: absolute;
  top: 32px;
  content: '';
  border-top: none;
  -webkit-transform: perspective(15px) rotateX(-5deg);
  -moz-transform: perspective(15px) rotateX(-5deg);
  transform: perspective(15px) rotateX(-5deg);
}

/* Text field password */
.MuiOutlinedInput-root {
  border: none;
}

/* to try out to make home btn in corporateprofilesetup to fix bottom */
.image {
  bottom: 0;
  right: 0;
}

/* to create a simple dropdownbar in traineeuseraccountsetup page */
.dropdown select {
  position: relative;
  width: 20rem;
  bottom: 4rem;
  left: 4rem;
  background: none;
  border: none;
  color:#FFD60F;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

/* for dropdownbar2 */
.dropdown2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown2 select {
  position: fixed;
  font-size: 1.2rem;
  background: none;
  border: none;
  color:#FFD60F;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  letter-spacing: 1px;
}

/* CSS grid for trainingCourseSetup page  */
/* .card-container {
  display: grid;
  grid-template-columns: 600px 600px 600px;
  grid-gap: 0rem;
  position: fixed;
  top: 22rem;
  left: 8rem;

} */

.prefix__cls-3 {
  width: 100%;
}

.levelSequence {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
  bottom: 6vh;
  left: 12vh;
  margin-left: 2vh;
  align-items: center;
}

.levelSequence p {
  color: #FDD84D;
  width: 8rem;
  margin-top: 1rem;
  margin-bottom: 0px;
  margin-left: 17px;
  font-family: impact;
  letter-spacing: 1px
}

/* to make the inputs in gallery authoring page able to take values */
.areaTextTitle {
    background: transparent;
    width: 100%;
    height: 21%;
    color: #FFD949;
    font-size: 15;
    line-height: 1.5,
    border none;
  }

  /* for the toggle button in gallery page */
  .Mui-selected {
    background-color: #FDD84D;
  }

  /* for the imageUploader in gallery authoring page */
.uploaderBox {
  display:flex;
}
.load {
  position: relative;
  top: 1vh;
  right:6rem;
  opacity: 0;
}
.load1 {
  position: relative;
  top: 1vh;
  right:6rem;
  opacity: 1;
}
.pic {
  cursor: pointer;
  padding-top: 2vh;
  padding-left: 2vh;
}
.imgUploadBtn {
  position: relative;
  top: 12vh;
  left: 4rem;
  opacity: 1;
}

/* experiment custom swiper */
.SwiperTestMainContainer {
  top: 6rem;
  width: 100vw;
  height: 64vh;
  display: flex;
  position: absolute;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.SwiperTestMainContainer h5 {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  letter-spacing: 2px;
  color: #FFD84E;
  margin-top: 0px;
  margin-bottom: 0px;
}

.SwiperTestMainContainer h3 {
  color: #fffffe;
  font-family: impact;
  font-size: 2.5rem
}

.swiperTestContainer {
  width: 100vw;
  height: 43vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.swiperTestContainer .swiperTestSlideHidden {
  display: none;
}
.swiperTestSlide1Hidden {
  display: none;
}

.arrowBox {
 position: fixed;
  display: flex;
  width: 100vw;
  justify-content: space-between;
  padding-left: 3rem;
  padding-right: 3rem;
  color: #ffffff;
  font-family: Impact;
  letter-spacing: 2px;
  cursor: pointer;
}

.arrowBox img {
  cursor: pointer;
}
.swiperTestPagination {
  width: 100vw;
  height: 73vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.swiperTestPagination p {
  color: #fffffe;
  font-family: impact;
  font-size: 2vh
}

.highlightRow {
  border: solid;
  border-width: 2px;
  color: white;
}

/* checkbox testing */
.check {
  color: #FFD84E;
}

.hidden {
  display: none;
}

.hiddenOpacity {
  opacity: 0;
}

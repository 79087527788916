.swiper-pagination{
    position:absolute;
    text-align:center;transition:.3s opacity;transform:translate3d(0,0,0);
    z-index:10;
    top: 24rem;
}

.swiper-pagination.swiper-pagination-hidden{
    opacity:0
z}

.swiper-container-horizontal>.swiper-pagination-bullets,.swiper-pagination-custom,.swiper-pagination-fraction{bottom:10px;left:0;width:100%}

.swiper-pagination-bullets-dynamic{overflow:hidden;font-size:0}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet{transform:scale(.33);position:relative}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active{transform:scale(1)}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main{transform:scale(1)}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev{transform:scale(.66)}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev{transform:scale(.33)}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next{transform:scale(.66)}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next{transform:scale(.33)}

.swiper-pagination-bullet{width:8px;height:8px;display:inline-block;border-radius:50%;background:rgb(255, 238, 0);opacity:.2}

button.swiper-pagination-bullet{border:none;margin:0;padding:0;box-shadow:none;-webkit-appearance:none;appearance:none}

.swiper-pagination-clickable .swiper-pagination-bullet{cursor:pointer}

.swiper-pagination-bullet-active{opacity:1;background:yellow,var(--swiper-theme-color))}
/* gochange change this */
.swiper-container-vertical>.swiper-pagination-bullets{right:10px;top:50%;transform:translate3d(0px,-50%,0)}

.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet{margin:6px 0;display:block}

.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic{top:50%;transform:translateY(-50%);width:8px}

.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet{display:inline-block;transition:.2s transform,.2s top}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet{margin:0 4px}

/* To move the two dots at the bottom of the slider */
.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic{left:48%;transform:translateX(-50%);white-space:nowrap;}
.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic{top:59%;transform:translateX(-50%);white-space:nowrap;}


.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet{transition:.2s transform,.2s left}

.swiper-container-horizontal.swiper-container-rtl>.swiper-pagination-bullets-dynamic .swiper-pagination-bullet{transition:.2s transform,.2s right}

.swiper-pagination-progressbar{background:rgba(255, 238, 0, 0.815);position:absolute}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill{background:var(--swiper-pagination-color,var(--swiper-theme-color));position:absolute;left:0;top:0;width:100%;height:100%;transform:scale(0);transform-origin:left top}

.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill{transform-origin:right top}

.swiper-container-horizontal>.swiper-pagination-progressbar,.swiper-container-vertical>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite{width:100%;height:4px;left:0;top:0}

.swiper-container-horizontal>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,.swiper-container-vertical>.swiper-pagination-progressbar{width:4px;height:100%;left:0;top:0}

.swiper-pagination-white{--swiper-pagination-color:#ffffff}.swiper-pagination-black{--swiper-pagination-color:#000000}.swiper-pagination-lock{display:none}
